import React, { useState, useRef, useEffect } from 'react';

import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';

import ComingSoon from '../assets/images/learn/coming-soon.png';
import ArrowDown from '../assets/images/learn/arrow-down.svg';
import PlayWhite from '../assets/images/icons/play-white-no-circle.svg';

import { submitForm } from '../utils/api';

const LOCAL_STORAGE_KEY = 'last_submitted_timestamp';

const Player = ({ source, title }) => {
  const player = useRef(null);

  return (
    <div className="position-relative full-height">
      <div
        style={{ padding: '42.19% 0 0 0', position: 'relative' }}
      >
        <iframe
          ref={player}
          src={source}
          frameBorder="0"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
          }}
          title={title}
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </div>
  );
};

function Episode({ data: { allMarkdownRemark, markdownRemark } }) {
  const { frontmatter, html } = markdownRemark;
  const {
    title, subheading, subtitle, episode, source, thumbnail, slug,
  } = frontmatter;
  const { nodes } = allMarkdownRemark;
  const episodes = nodes.map((n) => ({
    episode: n.frontmatter.episode,
    slug: n.frontmatter.slug,
    title: n.frontmatter.title,
    subtitle: n.frontmatter.subtitle,
    subheading: n.frontmatter.subheading,
    duration: n.frontmatter.duration,
    thumbnail: n.frontmatter.thumbnail,
  })).sort((a, b) => ((a.episode > b.episode) ? 1 : -1));


  const [showMoreEpisodes, setShowMoreEpisodes] = useState(false);
  const [showMoreTranscript, setShowMoreTranscript] = useState(false);
  const comingSoonEpisodes = [];

  for (let i = 11; i <= 11; i += 1) {
    comingSoonEpisodes.push(i);
  }

  const [allowPlayEpisode, setAllowPlayEpisode] = useState(false);

  const userName = useRef(null);
  const userEmail = useRef(null);
  const userOrganisation = useRef(null);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const submitInfo = (e) => {
    const values = {
      full_name: userName.current.value,
      email: userEmail.current.value,
      organisation: userOrganisation.current.value,
      partnership_type: 'Masterclass',
    };

    if (!userName.current.value) {
      setNameError(true);
    } else if (!userEmail.current.value) {
      setEmailError(true);
    } else {
      submitForm(values);
      setAllowPlayEpisode(true);
      window.localStorage.setItem(LOCAL_STORAGE_KEY, new Date().toString());
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (window.localStorage.getItem(LOCAL_STORAGE_KEY) != null) {
      setAllowPlayEpisode(true);
    }
  }, []);

  return (
    <Layout
      title={`Masterclass - ${title}`}
      description="Learn from the shoulders of Giants. We reveal the secrets from the battlefield in a series of masterclasses conducted by some of the most influential thinkers in the technology and legal services fields."
      image="/metaimages/masterclass.png"
      pageName="learn"
      requestDemo="explore"
    >
      <div className="episode-show-section">
        <div className="show-title fit-width">
          <div className="label">
            <div className="count">
              {`Episode ${episode}`}
            </div>
            <div className="title">
              {title}
              {
                subheading
                  ? (
                    <>
                      :
                      <br />
                      {subheading}
                    </>
                  ) : ''
              }
            </div>
          </div>
        </div>
        <div className="episode-show fit-width">
          <div className="episode">
            {
              allowPlayEpisode
                ? (
                  <div className="video">
                    <Player
                      title={`${title} - ${subheading}`}
                      source={source}
                    />
                  </div>
                )
                : (
                  <div className="video">
                    <div className="preview" style={{ backgroundImage: `url('${thumbnail}')` }}>
                      <div className="episode-form">
                        <div className="heading">Please tell us more about yourself to access the full masterclass</div>
                        <form
                          onSubmit={(e) => submitInfo(e)}
                        >
                          <input
                            className="episode-form-input"
                            ref={userName}
                            type="text"
                            placeholder="Name*"
                          />
                          <input
                            className="episode-form-input"
                            ref={userEmail}
                            type="email"
                            placeholder="Email*"
                          />
                          <input
                            className="episode-form-input"
                            ref={userOrganisation}
                            type="text"
                            placeholder="Organisation (optional)"
                          />
                          <div className="submit-group">
                            {
                            (emailError || nameError) ? (
                              <div className="error-message">*field required</div>
                            ) : (
                              <div />
                            )
                          }
                            <button>Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )
            }
            <div className="transcript -mobile">
              <div className="brief">
                <h1>Masterclass Breakdown</h1>
                <p>
                  {subtitle}
                </p>
              </div>
            </div>
            <div className="episode-list -episode">
              <h1 className="header">Episodes</h1>
              <div className="list">
                {episodes.map((e) => (
                  <Link to={e.slug} className="episode" key={e.slug}>
                    <div className="preview">
                      <img src={e.thumbnail} alt="preview" />
                      <button
                        className="play-button center -no-border"
                      >
                        <img src={PlayWhite} className="icon" alt="play icon" />
                      </button>
                    </div>
                    <div className={`details ${e.slug === slug ? '-active' : ''}`}>
                      <div className="count">
                        {`Episode ${e.episode}`}
                      </div>
                      <div className="title">{`${e.title} ${e.subheading ? `- ${e.subheading}` : ''}`}</div>
                      <div className="duration">{e.duration}</div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="list -coming-soon">
                {
                  comingSoonEpisodes.map((e) => (
                    <div className="episode" key={e}>
                      <div className="preview">
                        <img src={ComingSoon} alt="coming soon" />
                      </div>
                      <div className="details">
                        <div className="count">
                          Episode
                          {' '}
                          {e}
                        </div>
                        <div className="title">Coming Soon</div>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="list -coming-soon -mobile">
                {
                  showMoreEpisodes
                    && comingSoonEpisodes.map((e) => (
                      <div className="episode" key={e}>
                        <div className="preview">
                          <img src={ComingSoon} alt="coming soon" />
                        </div>
                        <div className="details">
                          <div className="count">
                            Episode
                            {' '}
                            {e}
                          </div>
                          <div className="title">Coming Soon</div>
                        </div>
                      </div>
                    ))
                }
              </div>
              <div className="show">
                <button className="button" onClick={() => setShowMoreEpisodes(!showMoreEpisodes)}>
                  <span className="label mr-3">
                    Show
                    {showMoreEpisodes ? ' less' : ' more'}
                  </span>
                  <img
                    className={showMoreEpisodes ? 'invert' : 'arrow'}
                    width="16"
                    height="10"
                    alt="arrow down"
                    src={ArrowDown}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="transcript">
            {
              allowPlayEpisode
                ? (
                  <div className="brief">
                    <h1>Masterclass Breakdown</h1>
                    <p className="content">
                      {subtitle}
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                )
                : (
                  <div className="brief">
                    <h1>Masterclass Breakdown</h1>
                    <p className="content">
                      {subtitle}
                    </p>
                  </div>
                )
            }
          </div>
          <div className="transcript -mobile">
            {
              allowPlayEpisode
                ? (
                  <>
                    <div
                      className="brief mb-16"
                      style={{
                        height: showMoreTranscript ? 'auto' : '540px',
                        overflow: showMoreTranscript ? 'visible' : 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                    <div className="show-more">
                      <button className="button" onClick={() => setShowMoreTranscript(!showMoreTranscript)}>
                        <span className="label mr-3">
                          {showMoreTranscript ? ' Show less' : 'Read full transcript'}
                        </span>
                        <img
                          className={showMoreTranscript ? 'invert' : 'arrow'}
                          width="16"
                          height="10"
                          alt="arrow down"
                          src={ArrowDown}
                        />
                      </button>
                    </div>
                  </>
                )
                : ''
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Episode;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subheading
        episode
        subtitle
        source
        thumbnail
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          duration
          episode
          title
          subheading
          subtitle
          slug
          thumbnail
        }
      }
    }
  }
`;
